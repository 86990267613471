<template>
  <v-sheet
    id="signed-feedback"
    color="transparent"
    class="text-center px-10"
  >
    <img src="@/assets/signed-feedback-icon.svg">
    <h4 class="title text-h6 mt-12">
      Tudo certo!
    </h4>
    <p 
      class="text text-body-1 my-8"
    >
      Sua assinatura foi registrada com sucesso! <b>Agora só falta a assinatura do proprietário para você seguir no processo.</b>
    </p>
    <p 
      class="text text-body-1 my-8"
    >
      Feche esta janela para voltar ao app (aperte o botão superior esquerdo da tela).
    </p>
  </v-sheet>
</template>

<style>

</style>

<script>

  // Icons
  import {
    mdiCheck,
  } from '@mdi/js'

  // Utilities
  import services from '@/services.js'
  import { sync } from 'vuex-pathify'
  import device from 'mobile-device-detect';


  export default {
    name: 'SignerFeedback',

    data: () => ({
      icons: {
        mdiCheck,
      },
    }),
  }
</script>
